<template>
    <div>  
        <Invite></Invite>
 </div>

</template>

<script>
import Invite from "../common/invite.vue"
export default {
   components:{
     Invite
   }
}
</script>